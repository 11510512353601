

const currentScript = document.currentScript;
const hostname = new URL(currentScript.src).hostname;
const baseUrl = 'https://' + hostname ;
// const baseUrl = 'https://backend.jovin.ai' ;

if (!currentScript.dataset.projectId) {
    throw new Error('Project ID missing.');
}




async function main() {


    // Don't run if this is a bot.
    const botFuse = navigator.userAgent.match(/bot|spider/i);

    if (botFuse) {
        console.log('Bot detected. Disabling Jovin...');
        return;
    }

    //don't load a widget if the user is blocked by cookie
    if (document.cookie.match(/^(.*;)?\s*__session_biccssf\s*=\s*[^;]+(.*)?$/) != null) {
        return;
    }

    // Create Jovin global object, which anyone can use to interface with the global script.
    window.botmanWidget = {
        endpoint: baseUrl + '/v1/chatbot/fbm/',
        uuid: currentScript.dataset.projectId,
        frameEndpoint: baseUrl + '/jovin-widget/chat.php',
        chatServerNotificator: 'https://jovin-release.spotwerbung.ch:8080/DAVOS_TEST/',
        placeholderText: 'Chatte mit mir…',
        sendWidgetOpenedEvent: true,
        useEcho: true,
        echoConfiguration: {},
        echoChannel: (userId) => 'humanSwitch.' + userId,
        echoEventName: 'HumanSwitch.SupportMessageReceived',
        echoChannelType: 'private',
        showLocaleToggler: currentScript.dataset.showLocaleToggler ?? false,
        locale: currentScript.dataset.locale ?? 'de',
        test: currentScript.dataset.test ?? 0
    };

    const blocksCss = document.createElement('link');
    blocksCss.href = baseUrl + '/jovin-widget/css/widget.css';
    blocksCss.rel = 'stylesheet';
    currentScript.parentNode.insertBefore(blocksCss, currentScript);

    const blocksScript = document.createElement('script');
    blocksScript.src = baseUrl + '/jovin-widget/js/widget.js';
    blocksScript.importance = 'high';
    blocksScript.id = 'botmanWidget';
    blocksScript.type = 'module';
    currentScript.parentNode.insertBefore(blocksScript, currentScript);

    window.addEventListener("load", (event) => {
        // Check if we need to remove widget from dom when user have been just block
        function utilityCalls() {
            let tmrUtilityCalls = setTimeout(() => {
                clearTimeout(tmrUtilityCalls);
                if (document.cookie.match(/^(.*;)?\s*__session_biccssf\s*=\s*[^;]+(.*)?$/) != null) {
                    try {
                        document.getElementById('botmanWidgetRoot').outerHTML = "";
                        document.getElementById('botmanWidget').outerHTML = "";
                        /*document.getElementById('script-jovin-1').outerHTML = "";*/
                        document.getElementById('script-jovin-2').outerHTML = "";
                    } catch (e){}
                    console.log("remove widget");
                    return;
                }
                utilityCalls();
            }, 1000);
        }
        utilityCalls();

        let autoOpen = function () {
            let tmr = setTimeout(() => {
                clearTimeout(tmr);
                if (document.querySelector('.mobile-closed-message-avatar') != null) {
                    document.querySelector('.mobile-closed-message-avatar').click();
                } else if (document.querySelector('.desktop-closed-message-avatar') != null) {
                    document.querySelector('.desktop-closed-message-avatar').click();
                } else {
                    autoOpen();
                }
            }, 200);
        };
        let params = location.search.split('?').join('');
        if (params.indexOf('action=start') >= 0) {
            autoOpen();
        }
    });
}

if (window.JOVIN_GLOBAL_LOADED !== true) {
    window.JOVIN_GLOBAL_LOADED = true;

    if (document.readyState !== 'loading') {
        main();
    } else {
        document.addEventListener('DOMContentLoaded', main);
    }
} else {
    console.warn('Jovin script was already loaded. Please remove additional copies to avoid any conflicts.');
}
